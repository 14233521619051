import React from "react";
import { Space, Card, Button } from 'antd';
import * as XLSX from 'xlsx';

interface IProps {
    totalPrice: number,
    isLoading: boolean,
    numberOfForms: number,
    totalPrize: number,
    totalOriginPrice?: number,
}

export const Stats: React.FC<IProps> = ({totalPrice, totalOriginPrice, isLoading, numberOfForms, totalPrize}) => {
    const handleExport = () => {
        // Prepare data for export
        const data = [
            ['Metric', 'Value'],
            ['Total Price', totalPrice.toFixed(2)],
            ['Total Prize', totalPrize.toFixed(2)],
            ['Number of Forms', numberOfForms],
        ];

        if (totalOriginPrice) {
            data.push(['Total Origin Price', totalOriginPrice.toFixed(2)]);
            data.push(['Total Revenue', (totalPrice - totalOriginPrice).toFixed(2)]);
        }

        // Create worksheet
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Stats');

        // Generate and download file
        XLSX.writeFile(wb, 'stats_export.xlsx');
    };

    return (
        <div>
            <Space direction="horizontal" size="large" style={{ display: 'flex', marginBottom: '16px' }}>
                <Card title="Total Price" size="small" loading={isLoading}>
                    <p>{totalPrice.toFixed(2)}</p>
                </Card>
                <Card title="Total Prize" size="small" loading={isLoading}>
                    <p>{totalPrize.toFixed(2)}</p>
                </Card>
                {totalOriginPrice && <><Card title="Total Origin Price" size="small" loading={isLoading}>
                    <p>{totalOriginPrice.toFixed(2)}</p>
                </Card>
                    <Card title="Total Revenue" size="small" loading={isLoading}>
                    <p>{(totalPrice - totalOriginPrice).toFixed(2)}</p>
                </Card></>}
                <Card title="Number Of Forms" size="small" loading={isLoading}>
                    <p>{numberOfForms}</p>
                </Card>
            </Space>
            <Button 
                type="primary" 
                onClick={handleExport}
                disabled={isLoading}
            >
                Export to Excel
            </Button>
        </div>
    );
}
